<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Exporteren</h1>
        <b-card class="mb-3">
            <b-button @click="exportOrders()" class="float-right mt-2 ml-2" variant="primary">
                <font-awesome-icon icon="file-export" class="mr-1" />
                Exporteren
            </b-button>
            <h3 class="fs-5 mb-0">Exporteer orderbestand</h3>
            <p class="text-muted mb-0">Exporteer alle orders van de organisatie.</p>
        </b-card>
        <b-card class="mb-3">
            <b-button @click="exportTickets()" class="float-right mt-2 ml-2" variant="primary">
                <font-awesome-icon icon="file-export" class="mr-1" />
                Exporteren
            </b-button>
            <h3 class="fs-5 mb-0">Exporteer ticketbestand</h3>
            <p class="text-muted mb-0">Exporteer alle tickets van de organisatie.</p>
        </b-card>
      </b-container>
    </div>
  </template>
  
  <script>
      export default {
          metaInfo: {
              title: 'Exporteren',
          },
          components: {
          },
          data() {
              return {
                exports: []
              }
          },
          computed: {},
          methods: {
            exportOrders: function() {
                this.$axios.get("https://api.tixgo.nl/organisations/export/orders", { responseType: 'blob' })
                    .then( response => {
                        let url = window.URL.createObjectURL(new Blob([response.data]));
                        let link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'organisation_orders_export.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            exportTickets: function() {
                this.$axios.get("https://api.tixgo.nl/organisations/export/tickets", { responseType: 'blob' })
                    .then( response => {
                        let url = window.URL.createObjectURL(new Blob([response.data]));
                        let link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'organisation_tickets_export.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            }
          },
          created() {}
      }
  </script>